import React from "react";

function Home() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        title="Eltropy-API"
        src="./Eltropy-APIs-v1.0.11.html"
        frameBorder="0"
        style={{ width: "100%", height: "100%" }}
      ></iframe>
    </div>
  );
}

export default Home;
